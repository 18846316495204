import styles from './v2/Layout.module.css';
import homeStyles from './v2/home.module.css';
import errorStyles from './404.module.css';
import Button from '../components/Button/CustomButton';
import Title from '../components/Title/title';

const redirectToHome = () => {
    window.location.href = '/home';
};
const renderHeader = () => {
    return (
        <div className={`${homeStyles.home_page_header}`}>
            <div className="logo">
                <img src="/billzy_logo.png" className="billzy_logo"></img>
            </div>
        </div>
    );
};
const render404pageContent = (props) => {
    const { isErrorPage = false } = props;
    return (
        <div className={errorStyles.pageContainer}>
            <div className={errorStyles.pageHeading}>
                {isErrorPage
                    ? 'Sorry! It’s not you, its us!!'
                    : 'Lost Your Way?'}
            </div>
            <div className={errorStyles.metaHeading}>
                {isErrorPage
                    ? 'An unexpected error occurred'
                    : "Sorry, we couldn't find the page. Find a lot more on our home page"}
            </div>
            <img
                src={
                    isErrorPage
                        ? '/errorInfoGraphic.svg'
                        : '/404InfoGraphic.svg'
                }
            ></img>
            <div className={errorStyles.footerButton}>
                <Button
                    text="Back to Home"
                    click={redirectToHome}
                    className={`${styles.cgbtn} ${styles.cgLargebtn} ${styles.cgLargebtnDestop}`}
                />
            </div>
        </div>
    );
};

export default function Custom404(props) {
    return (
        <div className={`${styles.pageContainer}`}>
            <Title value="404 | Billzy" />
            <div className={`${styles.pageLayout} `}>
                {renderHeader()}
                {render404pageContent(props)}
            </div>
        </div>
    );
}

Custom404.staticName = 'Custom404';
